import { CdkStepper } from '@angular/cdk/stepper';
import { Location, ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { NumericValueType, RxwebValidators } from '@rxweb/reactive-form-validators';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs';
import { ValidateAddressDialog } from "src/app/core/components/validate-address/validate-address.component";
import { businessStructure, stateOfIncorporation, states } from 'src/app/core/constants/dropdown-values';
import { FormErrorService } from 'src/app/core/services/form-error-service/form-error-service.service';
import { UspsService } from 'src/app/core/services/usps/usps.service';
import { getNAICSCode } from 'src/app/store/actions/bayfirst.actions';
import { AppState } from 'src/app/store/selectors/bayfirst.selector';

@Component({
  selector: 'app-business-information-form',
  templateUrl: './business-information-form.component.html',
  styleUrls: ['./business-information-form.component.scss']
})
export class BusinessInformationFormComponent implements OnInit {
  @Input('generalInformationFormGroup') generalInformationFormGroup: FormGroup;

  businessInformationFormGroup = this._formBuilder.group({
    legalBusinessName: ['', Validators.required],
    doingBusinessAs: [''],
    businessStructure: ['', Validators.required],
    doesYourBusinessFileTaxesOnAScheduleC: [''],
    numberOfEmployees: ['', Validators.compose([Validators.required, RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: false })])],
    dateBusinessWasEstablished: ['', Validators.compose([Validators.required])],
    stateOfIncorporation: ['', Validators.required],
    streetAddress: ['', Validators.required],
    suiteOrAPTName: [''],
    city: ['', Validators.required],
    state: ['', Validators.required],
    zip: ['', Validators.compose([Validators.required, Validators.pattern(/(^\d{5}$)/)])],
    businessPhoneNo: ['', Validators.required],
    NAICSCode: ['', Validators.required],
    isYourBusinessAFranchise: ['', Validators.required],
    employerIdentificationNumber: ['', Validators.required],
    whatIsTheCombinedFairMarketValueOfTheBusinessAssets: ['', Validators.compose([Validators.required, RxwebValidators.numeric({ acceptValue: NumericValueType.PositiveNumber, allowDecimal: true, isFormat: true })])],
    businessDescription: ['', Validators.required],
    isBusinessOwnAnyRealEstate: ['', Validators.required],
    isBusinessPendingLitigation: ['', Validators.required],
    isBusinessOutstandingJudgmentsOrTaxLiens: ['', Validators.required],
    isBusinessReceiveSbaLoanLast90Days: ['', Validators.required],
  });

  businessStructure: any[] = businessStructure;
  stateOfIncorporation: any[] = stateOfIncorporation;
  states: any[] = states;
  dateBusinessWasEstablishedMinDate: Date;
  dateBusinessWasEstablishedMaxDate: Date;
  isNaicsCodeNotFound: boolean = false;
  addressValidationLoading: boolean = false;
  isAddressNotFound: boolean = false;

  constructor(
    private _formBuilder: FormBuilder,
    private readonly _stepper: CdkStepper,
    private location: Location,
    private route: ActivatedRoute,
    private uspsService: UspsService,
    private scroller: ViewportScroller,
    public formErrorService: FormErrorService,
    public store: Store<AppState>,
    public dialog: MatDialog
  ) {
    const currentYear = new Date().getFullYear();
    this.dateBusinessWasEstablishedMinDate = new Date(currentYear - 100, 0, 1);
    this.dateBusinessWasEstablishedMaxDate = new Date();
  }

  ngOnInit(): void {
    this.businessInformationFormGroup.get('NAICSCode')?.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.isNaicsCodeNotFound = false)
    ).subscribe(res => {
      this.store.dispatch(getNAICSCode({ NAICSCode: res }))
    });
    this.businessInformationFormGroup.get('businessStructure')?.valueChanges.subscribe((val) => {
      const businessStructureSelected = this.businessStructure.filter(x => x.value == (val ?? ""))[0];
      if (businessStructureSelected.isScheduleCRequired) {
        this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.setValue(businessStructureSelected.scheduleCValue, { onlySelf: true, emitEvent: true });
        this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.setValidators([Validators.required]);
      }
      else {
        this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.clearValidators();
      }
      this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.updateValueAndValidity();
    });
    this.generalInformationFormGroup.controls['businessName'].valueChanges.subscribe(generalInformation => {
      this.businessInformationFormGroup.controls.legalBusinessName.setValue(generalInformation, { onlySelf: true, emitEvent: false });
    });
    this.businessInformationFormGroup.controls['legalBusinessName'].valueChanges.subscribe(legalBusinessName => { this.generalInformationFormGroup.controls['businessName'].setValue(legalBusinessName, { onlySelf: true, emitEvent: false });
    });
  }

  back() {
    this._stepper.previous();
    this.location.go("bolt/general", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  next() {
    if (this.businessInformationFormGroup.valid) {
      this.addressValidationLoading = true;
      this.isAddressNotFound = false;
      var address = {
        streetAddress: this.businessInformationFormGroup.get('streetAddress')?.value?.toUpperCase(),
        city: this.businessInformationFormGroup.get('city')?.value?.toUpperCase(),
        state: this.businessInformationFormGroup.get('state')?.value?.toUpperCase(),
        ZIPCode: this.businessInformationFormGroup.get('zip')?.value?.toUpperCase()
      };
      this.uspsService.address(address).subscribe({
        next: (res) => {
          var suggestedAddress = {
            streetAddress: res.address.streetAddress,
            city: res.address.city ,
            state: res.address.state,
            ZIPCode: res.address.ZIPCode
          };
          if (address.streetAddress != suggestedAddress.streetAddress || address.city != suggestedAddress.city || address.state != suggestedAddress.state || address.ZIPCode != suggestedAddress.ZIPCode) {
            this.LoadAddressDialog(address, suggestedAddress, true);
          } else {
            this.addressValidationLoading = false;
            this.isAddressNotFound = false;
            this._stepper.next();
            this.location.go("bolt/owners", `broker=${this.route.snapshot.queryParams['broker']}`);
          }
        },
        error: (err) => {
          this.LoadAddressDialog(address, null, false);
        }
      });
    }
    else {
      this.businessInformationFormGroup.markAllAsTouched();
    }
  }

  LoadAddressDialog(address: any, suggestedAddress: any, hasMatch: boolean) {
    const dialogRef = this.dialog.open(ValidateAddressDialog, {
      data: {address: address, suggestedAddress: suggestedAddress, hasMatch: hasMatch},
      width: '800px',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed().subscribe(res => {
      this.addressValidationLoading = false;      
      if (res) {
        this.businessInformationFormGroup.get('streetAddress')?.setValue(res.streetAddress);
        this.businessInformationFormGroup.get('city')?.setValue(res.city);
        this.businessInformationFormGroup.get('state')?.setValue(res.state);
        this.businessInformationFormGroup.get('zip')?.setValue(res.ZIPCode);
        this.isAddressNotFound = false;
        this._stepper.next();
        this.location.go("bolt/owners", `broker=${this.route.snapshot.queryParams['broker']}`);
      }
      else {
        this.isAddressNotFound = true;
        this.scroller.scrollToAnchor('addressBlock');
      }
    });
  }

  displayBusinessStructureRestricted(): boolean {
    const businessStructureSelected = this.businessStructure.filter(x => x.value == this.businessInformationFormGroup.get('businessStructure')?.value)[0];
    return businessStructureSelected?.displayScheduleC ?? false;
  }
}
