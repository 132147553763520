<div class="header-action-button">
  <button mat-icon-button class="close-button" [mat-dialog-close]="false">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div class="flex flex-col">
    <div class="flex flex-col">
      <h3>Please review the Terms and Conditions provided below. If you agree, confirm your acceptance before submitting
        your application.</h3>
      <h2 class="text-center">Terms and Conditions</h2>
      <div class="mat-body-1">
        <p>I understand that this loan application is for a business loan with BayFirst National Bank (“BayFirst”). The funds can only be used for business related purposes. I authorize BayFirst or investigative agency employed by BayFirst to check my references, credit and employment history. I understand that a consumer report may be requested in connection with this credit application.  BayFirst may at any time in the future obtain additional credit reports to review my account.</p>
        <p>I authorize BayFirst National Bank to release and otherwise share my private personal and business information with my SBA Approved Counseling Center (SBDC or SCORE) and the broker which referred the loan application to BayFirst National Bank, and likewise, for my Counseling Center and or broker to release and other wise share my private personal and business information with BayFirst National Bank.</p>
        <p>BayFirst may, at its discretion, file one or more UCC-1’s prior to the Closing Date to ensure the Bank’s priority interest in the collateral required by the Bank or the SBA to secure the payment of the principal, interest and all other amounts due with regard to the Loan made by the Bank to Borrower and/or any entity that Borrower may form in connection with the Loan. If this Loan does not close for any reason, the Bank will file a UCC-3 terminating its interests. SBA permits a third party to charge an Applicant fees for packaging and other services. Prior to any services being provided, the 7(a) Lender must advise the Applicant in writing that the Applicant is not required to obtain or pay for unwanted services.</p>
        <p>I authorize BayFirst National Bank to contact me through a landline, a paging service, a cellular wireless service, a specialized mobile radio service, other radio common carrier service or any other service for which I may be charged for the call. I further authorize BayFirst to contact me through the use of voice, voice mail and text messaging, including the use of pre-recorded or artificial voice messages and an automated dialing device. If necessary, I may change or remove any of the telephone numbers or email addresses at any time using any reasonable means to notify BayFirst. This consent updates any previous consent and further supersedes previous revocations of consent, if any, by me.</p>
        <p>By submitting this form, I certify that I am the named person on this form, am authorized to act on behalf of the requesting business and agree to the terms above.</p>
      </div>
    </div>
    <form [formGroup]="termsAndConditionsFormGroup" (ngSubmit)="onSubmit()" class="flex flex-col gap-5">
      <div class="mat-form-field">
        <mat-checkbox formControlName="acceptTermsAndConditions" color="accent" required>
          <mat-label [ngClass]="{'error-message': !termsAndConditionsFormGroup.get('acceptTermsAndConditions')?.valid && termsAndConditionsFormGroup.get('acceptTermsAndConditions')?.touched}">I agree to the Terms and Conditions</mat-label>
        </mat-checkbox>
      </div>
      <button mat-flat-button color="accent" class="action-button self-center" type="submit" [disabled]="!termsAndConditionsFormGroup.get('acceptTermsAndConditions')?.valid">
        Submit Application
      </button>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
</mat-dialog-actions>