<div class="stepper-header flex flex-row justify-center">
    <button mat-button (click)="back()" color="accent" class="stepper-previous">
        <mat-icon>keyboard_arrow_left</mat-icon> Back
    </button>
    <div class="text-center">
        <h1 class="mat-h1 mb-0">Business Information</h1>
    </div>
</div>
<div class="flex flex-row justify-center grid grid-cols-12">
    <div
        class="flex flex-col lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <div class="sub-header">
            <h1 class="mat-h1 mb-0">Business Details</h1>
            <mat-divider></mat-divider>
        </div>
        <form [formGroup]="businessInformationFormGroup" class="flex flex-col">
            <mat-label>Legal business name *</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('legalBusinessName')" />

            <mat-label>Doing business as (if applicable)</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('doingBusinessAs')" />

            <mat-label>Business structure *</mat-label>
            <app-form-select-field [control]="$any(businessInformationFormGroup).get('businessStructure')" [options]="businessStructure" placeholder="Please select" />

            <ng-container *ngIf="displayBusinessStructureRestricted()">
                <mat-label>Does your business file taxes on a schedule C? *</mat-label>
                <app-form-radio-group [control]="$any(businessInformationFormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')" />
            </ng-container>

            <mat-label>Number of employees *</mat-label>
            <app-form-input-number-field [control]="$any(businessInformationFormGroup).get('numberOfEmployees')" />

            <mat-label>Date business was established *</mat-label>
            <app-form-date-picker-field [control]="$any(businessInformationFormGroup).get('dateBusinessWasEstablished')"
                [minDate]="dateBusinessWasEstablishedMinDate" [maxDate]="dateBusinessWasEstablishedMaxDate" />

            <mat-label>State of incorporation *</mat-label>
            <app-form-select-field [control]="$any(businessInformationFormGroup).get('stateOfIncorporation')"
                [options]="stateOfIncorporation" placeholder="Please select" />

            <div class="sub-header" id="addressBlock">
                <h1 class="mat-h1 mb-0">Business Physical Address</h1>
                <mat-divider></mat-divider>
            </div>
            <div class="form-error-div" *ngIf="isAddressNotFound">
                <p>Please fix following errors</p>
                <ul>
                    <li>Address not found</li>
                </ul>
            </div>
            <mat-label>Street address *</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('streetAddress')" />

            <mat-label>Suite or apt name (if applicable)</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('suiteOrAPTName')" />

            <mat-label>City *</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('city')" />

            <div class="grid grid-cols-2 gap-2.5">
                <div class="flex flex-col">
                    <mat-label>State *</mat-label>
                    <app-form-select-field [control]="$any(businessInformationFormGroup).get('state')"
                        [options]="states" placeholder="Please select" />
                </div>
                <div class="flex flex-col">
                    <mat-label>Zip code *</mat-label>
                    <app-form-input-field [control]="$any(businessInformationFormGroup).get('zip')" mask="00000" />
                </div>
            </div>

            <mat-label>Business phone number *</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('businessPhoneNo')"
                mask="(000) 000-0000" [showMaskTyped]="true" />

            <div class="sub-header">
                <h1 class="mat-h1 mb-0">Business Industry or NAICS code</h1>
                <mat-divider></mat-divider>
            </div>
            <div>
                <span class="mat-body-1">
                    Please specify your business industry using relevant keywords or
                    provide the 6-digit NAICS code *
                </span>
            </div>

            <mat-label class="warning-text mat-subtitle-2">What is your 6-digit NAICS code?</mat-label>
            <app-naicscode [isNaicsCodeNotFound]="isNaicsCodeNotFound"
                [control]="$any(businessInformationFormGroup).get('NAICSCode')"></app-naicscode>

            <div class="sub-header">
                <h1 class="mat-h1 mb-0">Business Operations</h1>
                <mat-divider></mat-divider>
            </div>

            <mat-label>Is your business a franchise? *</mat-label>
            <app-form-radio-group [control]="$any(businessInformationFormGroup).get('isYourBusinessAFranchise')" />

            <mat-label>Please enter the Employer Identification Number (EIN) *</mat-label>
            <app-form-input-field [control]="$any(businessInformationFormGroup).get('employerIdentificationNumber')"
                mask="XX-XXXXXXX" [showMaskTyped]="true" placeHolderCharacter="X" />

            <mat-label>What is the combined fair market value of the business assets? *</mat-label>
            <app-form-input-field
                [control]="$any(businessInformationFormGroup).get('whatIsTheCombinedFairMarketValueOfTheBusinessAssets')"
                [isCurrency]="true" mask="separator.2">
                <span matTextPrefix>$&nbsp;</span>
            </app-form-input-field>

            <mat-label>Provide a brief description of the management team, time under current management, and
                depth of industry experience *</mat-label>
            <app-form-textarea-field [control]="$any(businessInformationFormGroup).get('businessDescription')" />

            <div class="sub-header">
                <h1 class="mat-h1 mb-0">Business Eligibility</h1>
                <mat-divider></mat-divider>
            </div>

            <mat-label>Does the business entity (or an affiliated real estate holding company) own any real estate?
                *</mat-label>
            <app-form-radio-group [control]="$any(businessInformationFormGroup).get('isBusinessOwnAnyRealEstate')" />

            <mat-label>Is this business or are any of the business affiliates involved in any pending
                litigation? *</mat-label>
            <app-form-radio-group [control]="$any(businessInformationFormGroup).get('isBusinessPendingLitigation')" />

            <mat-label>Does the business or any of its owners have any outstanding judgments or tax liens?
                *</mat-label>
            <app-form-radio-group
                [control]="$any(businessInformationFormGroup).get('isBusinessOutstandingJudgmentsOrTaxLiens')" />

            <mat-label>Have you received an SBA loan within the last 90 days from any bank?</mat-label>
            <app-form-radio-group
                [control]="$any(businessInformationFormGroup).get('isBusinessReceiveSbaLoanLast90Days')" />

            <div class="flex flex-col items-center">
                <span class="mat-caption flex encriptionMessage text-grey">
                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                    <span> Your information is secure with 256-bit encription</span>
                </span>
                <button mat-flat-button (click)="next()" color="accent" class="action-button"
                    [disabled]="!businessInformationFormGroup.valid || addressValidationLoading">
                    <mat-icon *ngIf="addressValidationLoading"><mat-spinner color="primary" diameter="16">
                        </mat-spinner></mat-icon> Continue
                </button>
            </div>
        </form>
    </div>
</div>