import { CdkStepper } from '@angular/cdk/stepper';
import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Subject, takeUntil } from 'rxjs';
import { AppDialog } from 'src/app/core/components/dialog/dialog.component';
import { businessStructure } from 'src/app/core/constants/dropdown-values';
import { OwnersFormService } from 'src/app/core/services/OwnersForm.service';
import { application, applicationDeclined, applicationFailure, applicationSuccess, documents, documentsFailure, documentsSuccess, prequalification, prequalificationDeclined, prequalificationFailure, prequalificationSuccess } from 'src/app/store/actions/bayfirst.actions';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
  documentsFormGroup = this._formBuilder.group({
    secondCtrl: ['',],
  });
  files: any[] = [];
  owners: any[] = [];
  showProgressBlock: boolean = false;
  showSuccessBlock: boolean = false;
  showDeclinedBlock: boolean = false;
  showFailureBlock: boolean = false;
  message: string;
  numberOfFiles: number;
  businessStructure: any[] = businessStructure;

  @Input('businessInformationFormGroup') businessInformationFormGroup: FormGroup;
  @Output('formSubmit') formSubmit = new EventEmitter<any>();

  prequalificationLoading: boolean;
  prequalificationSuccess: boolean;
  applicationLoading: boolean;
  documentsLoading: boolean;
  applicationSuccess: boolean;
  documentsSuccess: boolean;
  destroyed$ = new Subject<boolean>();

  constructor(
    private _formBuilder: FormBuilder,
    public dialog: MatDialog,
    private readonly _stepper: CdkStepper,
    private location: Location,
    private route: ActivatedRoute,
    private actions$: Actions,
    private ownersFormService: OwnersFormService
  ) {
  }

  ngOnInit(): void {
    if (this.ownersFormService) {
      this.ownersFormService.getOwnersFormGroup().valueChanges.subscribe(x => {
        this.owners = x.owners;
        this.numberOfFiles = 2;
        if (this.owners && this.owners.length > 0) {
          this.owners.forEach(x => {
            const businessStructureSelected = this.businessStructure.filter(x => x.value == this.businessInformationFormGroup.get('businessStructure')?.value)[0];
            if (businessStructureSelected.isScheduleCRequired && this.businessInformationFormGroup.get('doesYourBusinessFileTaxesOnAScheduleC')?.value == 'yes') {
              this.numberOfFiles = this.numberOfFiles + 1;
            }
            else {
              this.numberOfFiles = this.numberOfFiles + 2;
            }
            if (x.isOwnerBusinessOrIndividual == 'business') {
              if (x.owners && x.owners.length > 0) {
                this.numberOfFiles = this.numberOfFiles + (x.owners.length * 2);
              }
            }
          });
        }
      });
    }
    this.actions$.pipe(
      ofType(
        prequalification,
        prequalificationSuccess,
        prequalificationDeclined,
        prequalificationFailure,
        application,
        applicationSuccess,
        applicationDeclined,
        applicationFailure,
        documents,
        documentsSuccess,
        documentsFailure
      ),
      takeUntil(this.destroyed$)
    ).subscribe((action: any) => {
      switch (action.type) {
        case prequalification.type:
          this.prequalificationLoading = true;
          break;
        case prequalificationSuccess.type:
          this.prequalificationLoading = false;
          this.prequalificationSuccess = true;
          break;
        case prequalificationDeclined.type:
          this.message = action.message;
          this.prequalificationLoading = false;
          this.showDeclinedBlock = true;
          break;
        case prequalificationFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
        case application.type:
          this.applicationLoading = true;
          break;
        case applicationSuccess.type:
          this.message = action.payload.message;
          this.applicationLoading = false;
          this.applicationSuccess = true;
          break;
        case applicationDeclined.type:
          this.message = action.message;
          this.applicationLoading = false;
          this.showDeclinedBlock = true;
          break;
        case applicationFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
        case documents.type:
          this.documentsLoading = true;
          break;
        case documentsSuccess.type:
          this.documentsLoading = false;
          this.documentsSuccess = true;
          this.showSuccessBlock = true;
          break;
        case documentsFailure.type:
          this.message = action.error;
          this.showFailureBlock = true;
          break;
      }
    });
  }

  back() {
    this._stepper.previous();
    this.location.go("bolt/allocation", `broker=${this.route.snapshot.queryParams['broker']}`);
  }

  openDialog() {
    const dialogRef = this.dialog.open(AppDialog, {
      panelClass: 'modal-class'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.showProgressBlock = true;
        this.formSubmit.emit();
      }
    });
  }

  fileUploadHandler($event: any[], isBusinessDocument: boolean, isEntity: boolean, taxIdNumber: string, documentType: string) {
    const fileIndex = this.files.findIndex(x => x.isBusinessDocument == isBusinessDocument && x.taxIdNumber == taxIdNumber && x.isEntity == isEntity && x.documentType == documentType);
    if (fileIndex != -1) {
      this.files.splice(fileIndex, 1);
    };
    $event.forEach(file => {
      this.files.push({
        "brokerIdentifier": "",
        "applicationReferenceId": "",
        "ownerReferenceId": "",
        "documentType": documentType,
        "fileName": file.name,
        "fileData": file.result,
        "isBusinessDocument": isBusinessDocument,
        "taxIdNumber": taxIdNumber,
        "isEntity": isEntity
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  displayBusinessStructureRestricted(businessStructure: string, doesYourBusinessFileTaxesOnAScheduleC: string): boolean {
    if (this.businessStructure?.length > 0) {
      const businessStructureSelected = this.businessStructure.filter(x => x.value == businessStructure)[0];
      if (businessStructureSelected && !businessStructureSelected.isScheduleCRequired) {
        return true;
      }
    }
    return doesYourBusinessFileTaxesOnAScheduleC == "no";
  }

  hasOwnershipForDocuments(ownershipPercentage: string): boolean {
    return Number(ownershipPercentage) >= 20;
  }
}
