<div class="file-container" appDragAndDrop (fileDropped)="onFileDropped($event)">
    <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event)" />
    <div class="flex flex-col justify-center items-center gap-2.5">
        <mat-icon svgIcon="upload"></mat-icon>
        <span class="mat-h3">Click here to browse or drop here</span>
    </div>
</div>
<ng-container *ngFor="let file of files; let i = index; let last = last">
    <app-file-item [fileName]="file?.name" [percentage]="file?.progress" (deleteFile)="deleteFile($event)"></app-file-item>        
    <mat-divider *ngIf="!last"></mat-divider>
</ng-container>