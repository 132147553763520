<mat-toolbar>
  <mat-toolbar-row *ngIf="environment.showToolbar" class="text-environment text-environment-{{environment.name}}">
    <span>{{environment.name}}</span>
  </mat-toolbar-row>
  <mat-toolbar-row>
    <img src="../assets/svg/logo.svg" alt="BayFirst" />
    <span class="grow"></span>
    <!-- <span class="flex flex-row justify-center call">
      <img src="../assets/svg/call.svg" alt="call" />833-564-2254
    </span> -->
  </mat-toolbar-row>
</mat-toolbar>
<div class="content flex flex-row justify-center">
  <div class="container">
    <router-outlet></router-outlet>
    <div *ngIf="loading$ | async" class="flex flex-row justify-center loading-block">
      <div class="flex flex-col justify-center">
        <mat-spinner class="search-spinner" diameter="50"></mat-spinner>
      </div>
    </div>
  </div>
</div>
<footer>
  © {{currentYear}} BayFirst National Bank, Member FDIC, Equal Housing Lender
</footer>