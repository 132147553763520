<div class="stepper-header flex flex-row justify-center" *ngIf="!showProgressBlock">
    <button mat-button (click)="back()" color="accent" class="stepper-previous">
        <mat-icon>keyboard_arrow_left</mat-icon> Back
    </button>
    <div class="text-center">
        <h1 class="mat-h1 mb-0">Upload Documents</h1>
    </div>
</div>
<div *ngIf="!showProgressBlock" class="flex flex-row justify-center grid grid-cols-12">
    <div class="flex flex-col lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <form [formGroup]="documentsFormGroup" class="documents-container flex flex-col">
            <div class="mb-1">
                <p class="mat-body-1">
                    Please upload the documents required for your application. Depending on your particular circumstance, you may need to provide additional documents later.
                </p>
                <p class="mat-h3 primary italic my-0">
                    Accepted file format: PDF, JPG, PNG, Word or Excel.
                </p>
                <p class="mat-h3 primary italic my-0">
                    Max file size: 100MB
                </p>
            </div>
            <mat-divider></mat-divider>
            <div class="bold f-1-2 mt-1">Documents for {{ $any(businessInformationFormGroup).get('legalBusinessName')?.value | titlecase }}</div>
            <mat-label class="mat-h3 my-1">Business Tax Return (most recent)*</mat-label>
            <app-file-upload (fileUploadHandler)="fileUploadHandler($event, true, true, $any(businessInformationFormGroup).get('employerIdentificationNumber')?.value, 'Tax_Return_Year_1')"></app-file-upload>
            <mat-label class="mat-h3 my-1">Business Tax Return (prior year)*</mat-label>
            <app-file-upload (fileUploadHandler)="fileUploadHandler($event, true, true, $any(businessInformationFormGroup).get('employerIdentificationNumber')?.value, 'Tax_Return_Year_2')"></app-file-upload>
            <ng-container *ngFor="let owner of owners; let i = index">
                <ng-container *ngIf="hasOwnershipForDocuments(owner.ownershipPercentage)">
                    <ng-container *ngIf="owner.isOwnerBusinessOrIndividual == 'individual'">
                        <mat-divider class="mt-1"></mat-divider>
                        <div class="bold f-1-2 mt-1">Documents for {{ owner?.fname | titlecase }} {{ owner?.lname | titlecase}}</div>
                        <ng-container *ngIf="displayBusinessStructureRestricted($any(businessInformationFormGroup).get('businessStructure')?.value, $any(businessInformationFormGroup).get('doesYourBusinessFileTaxesOnAScheduleC')?.value)">
                            <mat-label class="mat-h3 my-1">Personal Federal Tax Return (most recent) *</mat-label>
                            <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, false, owner.ssn, 'Tax_Return_Year_1')"></app-file-upload>
                        </ng-container>
                        <mat-label class="mat-h3 my-1">Driver's License *</mat-label>
                        <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, false, owner.ssn, 'Drivers_License')"></app-file-upload>
                    </ng-container>
                    <ng-container *ngIf="owner.isOwnerBusinessOrIndividual == 'business'">
                        <mat-divider class="mt-1"></mat-divider>
                        <div class="bold f-1-2 mt-1">Documents for {{ owner?.legalBusinessName | titlecase }}</div>
                        <mat-label class="mat-h3 my-1">Business Tax Return (most recent)*</mat-label>
                        <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, true, owner.employerIdentificationNumber, 'Tax_Return_Year_1')"></app-file-upload>
                        <mat-label class="mat-h3 my-1">Business Tax Return (prior year)*</mat-label>
                        <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, true, owner.employerIdentificationNumber, 'Tax_Return_Year_2')"></app-file-upload>
                        <ng-container *ngFor="let ownerOwner of owner.owners; let j = index">
                            <ng-container *ngIf="hasOwnershipForDocuments(ownerOwner.ownershipPercentage)">
                                <ng-container *ngIf="ownerOwner.isOwnerBusinessOrIndividual == 'individual'">
                                    <mat-divider class="mt-1"></mat-divider>
                                    <div class="bold f-1-2 mt-1">Documents for {{ ownerOwner?.fname | titlecase }} {{ ownerOwner?.lname | titlecase}}</div>
                                    <ng-container *ngIf="displayBusinessStructureRestricted(owner?.businessStructure, owner?.doesYourBusinessFileTaxesOnAScheduleC)">
                                        <mat-label class="mat-h3 my-1">Personal Federal Tax Return (most recent) *</mat-label>
                                        <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, false, ownerOwner.ssn, 'Tax_Return_Year_1')"></app-file-upload>
                                    </ng-container>
                                    <mat-label class="mat-h3 my-1">Driver's License *</mat-label>
                                    <app-file-upload (fileUploadHandler)="fileUploadHandler($event, false, false, ownerOwner.ssn, 'Drivers_License')"></app-file-upload>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
            <mat-divider class="mt-1"></mat-divider>
            <div class="flex flex-col items-center">
                <span class="mat-caption flex encriptionMessage text-grey gap-1">
                    <mat-icon svgIcon="lock" class="lock-icon"></mat-icon>
                    <span>Your information is secure with 256-bit encription</span>
                </span>
                <button mat-flat-button (click)="openDialog()" [disabled]="files.length != numberOfFiles" color="accent" class="action-button">Review Terms and Conditions</button>
            </div>
        </form>
    </div>
</div>
<div *ngIf="showProgressBlock" class="flex flex-row justify-center grid grid-cols-1">
    <div class="flex flex-col lg:col-start-4 lg:col-span-6 md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10 col-start-2 col-span-10">
        <div class="flex flex-row items-center justify-center submit-response-block">
            <div *ngIf="!showSuccessBlock && !showDeclinedBlock && !showFailureBlock" class="flex flex-row items-center justify-center">
                <div class="items-center justify-center submit-response-block">
                    <div class="flex flex-row">
                        <div class="flex flex-col">
                            <div class="flex flex-row">
                                <div>
                                    <mat-icon color="accent" class="icon-left">task_alt</mat-icon>
                                </div>
                                <div class="icon-right">
                                    <mat-progress-bar *ngIf="prequalificationLoading" color="primary" mode="indeterminate"></mat-progress-bar>
                                    <mat-progress-bar *ngIf="!prequalificationLoading" [value]="prequalificationSuccess ? 100 : 0" color="primary" mode="determinate"></mat-progress-bar>
                                </div>  
                            </div>
                            <div class="flex flex-col">
                                <div class="title">Verifying Application</div>
                                <div class="sub-title">{{ prequalificationLoading ? 'Processing...' : prequalificationSuccess ? 'Completed' : 'waiting' }}</div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="items-center justify-center submit-response-block">
                    <div class="flex flex-row">
                        <div class="flex flex-col">
                            <div class="flex flex-row">
                                <div>
                                    <mat-icon color="accent" class="icon-left">save</mat-icon>
                                </div>
                                <div class="icon-right">
                                    <mat-progress-bar *ngIf="applicationLoading" color="primary" mode="indeterminate"></mat-progress-bar>
                                    <mat-progress-bar *ngIf="!applicationLoading" [value]="applicationSuccess ? 100 : 0" color="primary" mode="determinate"></mat-progress-bar>
                                </div>  
                            </div>
                            <div class="flex flex-col">
                                <div class="title">Submitting Application</div>
                                <div class="sub-title">{{ applicationLoading ? 'Processing...' : applicationSuccess ? 'Completed' : 'Waiting' }}</div>  
                            </div>
                        </div>
                    </div>
                </div>
                <div class="items-center justify-center submit-response-block">
                    <div class="flex flex-row">
                        <div class="flex flex-col">
                            <div class="flex flex-row">
                                <div>
                                    <mat-icon color="accent" class="icon-left">upload_file</mat-icon>
                                </div>
                                <div class="icon-right">
                                    <mat-progress-bar *ngIf="documentsLoading" color="primary" mode="indeterminate"></mat-progress-bar>
                                    <mat-progress-bar *ngIf="!documentsLoading" [value]="documentsSuccess ? 100 : 0" color="primary" mode="determinate"></mat-progress-bar>
                                </div>  
                            </div>
                            <div class="flex flex-col">
                                <div class="title">Uploading Documents</div>
                                <div class="sub-title">{{ documentsLoading ? 'Processing...' : documentsSuccess ? 'Completed' : 'Waiting' }}</div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" *ngIf="showSuccessBlock">
                <mat-icon svgIcon="submit-success"></mat-icon>
                <h1>Thank you for your loan application with Bayfirst.</h1>
                <div *ngIf="message">
                    <div [innerHTML]="message"></div>
                </div>
            </div>
            <div class="text-center" *ngIf="showDeclinedBlock">
                <mat-icon svgIcon="submit-error"></mat-icon>
                <div *ngIf="message" class="mt-3">
                    <div [innerHTML]="message"></div>
                </div>
            </div>
            <div class="text-center" *ngIf="showFailureBlock">
                <mat-icon svgIcon="submit-error"></mat-icon>
                <h1>Oops!</h1>
                <div *ngIf="message">
                    <div [innerHTML]="message"></div>
                </div>
            </div>
        </div>
    </div>
</div>
