export const businessStructure = [
    { value: "C-Corporation", viewValue: "C-Corp", isScheduleCRequired: false, displayScheduleC: false, scheduleCValue: "" },
    { value: "S-Corporation", viewValue: "S-Corp", isScheduleCRequired: false, displayScheduleC: false, scheduleCValue: "" },
    { value: "General Partnership", viewValue: "General Partnership", isScheduleCRequired: false, displayScheduleC: false, scheduleCValue: "" },
    { value: "Limited Liability Company", viewValue: "Limited Liability Company", isScheduleCRequired: true, displayScheduleC: true, scheduleCValue: "" },
    { value: "Limited Liability Partnership", viewValue: "Limited Liability Partnership", isScheduleCRequired: false, displayScheduleC: false, scheduleCValue: "" },
    { value: "Sole Proprietorship", viewValue: "Sole Proprietorship", isScheduleCRequired: true, displayScheduleC: false, scheduleCValue: "yes" }
];

export const stateOfIncorporation = [
    { value: "AL", viewValue: "Alabama" },
    { value: "AK", viewValue: "Alaska" },
    { value: "AS", viewValue: "American Samoa" },
    { value: "AZ", viewValue: "Arizona" },
    { value: "AR", viewValue: "Arkansas" },
    { value: "CA", viewValue: "California" },
    { value: "CO", viewValue: "Colorado" },
    { value: "CT", viewValue: "Connecticut" },
    { value: "DE", viewValue: "Delaware" },
    { value: "DC", viewValue: "District of Columbia" },
    { value: "FM", viewValue: "Federated States of Micronesia" },
    { value: "FL", viewValue: "Florida" },
    { value: "GA", viewValue: "Georgia" },
    { value: "GU", viewValue: "Guam" },
    { value: "HI", viewValue: "Hawaii" },
    { value: "ID", viewValue: "Idaho" },
    { value: "IL", viewValue: "Illinois" },
    { value: "IN", viewValue: "Indiana" },
    { value: "IA", viewValue: "Iowa" },
    { value: "KS", viewValue: "Kansas" },
    { value: "KY", viewValue: "Kentucky" },
    { value: "LA", viewValue: "Louisiana" },
    { value: "ME", viewValue: "Maine" },
    { value: "MH", viewValue: "Marshall Islands" },
    { value: "MD", viewValue: "Maryland" },
    { value: "MA", viewValue: "Massachusetts" },
    { value: "MI", viewValue: "Michigan" },
    { value: "MN", viewValue: "Minnesota" },
    { value: "MS", viewValue: "Mississippi" },
    { value: "MO", viewValue: "Missouri" },
    { value: "MT", viewValue: "Montana" },
    { value: "NE", viewValue: "Nebraska" },
    { value: "NV", viewValue: "Nevada" },
    { value: "NH", viewValue: "New Hampshire" },
    { value: "NJ", viewValue: "New Jersey" },
    { value: "NM", viewValue: "New Mexico" },
    { value: "NY", viewValue: "New York" },
    { value: "NC", viewValue: "North Carolina" },
    { value: "ND", viewValue: "North Dakota" },
    { value: "MP", viewValue: "Northern Mariana Islands" },
    { value: "OH", viewValue: "Ohio" },
    { value: "OK", viewValue: "Oklahoma" },
    { value: "OR", viewValue: "Oregon" },
    { value: "PW", viewValue: "Palau" },
    { value: "PA", viewValue: "Pennsylvania" },
    { value: "PR", viewValue: "Puerto Rico" },
    { value: "RI", viewValue: "Rhode Island" },
    { value: "SC", viewValue: "South Carolina" },
    { value: "SD", viewValue: "South Dakota" },
    { value: "TN", viewValue: "Tennessee" },
    { value: "TX", viewValue: "Texas" },
    { value: "UT", viewValue: "Utah" },
    { value: "VT", viewValue: "Vermont" },
    { value: "VI", viewValue: "Virgin Islands" },
    { value: "VA", viewValue: "Virginia" },
    { value: "WA", viewValue: "Washington" },
    { value: "WV", viewValue: "West Virginia" },
    { value: "WI", viewValue: "Wisconsin" },
    { value: "WY", viewValue: "Wyoming" }
];

export const states = [
    { value: "AL", viewValue: "Alabama" },
    { value: "AK", viewValue: "Alaska" },
    { value: "AS", viewValue: "American Samoa" },
    { value: "AZ", viewValue: "Arizona" },
    { value: "AR", viewValue: "Arkansas" },
    { value: "CA", viewValue: "California" },
    { value: "CO", viewValue: "Colorado" },
    { value: "CT", viewValue: "Connecticut" },
    { value: "DE", viewValue: "Delaware" },
    { value: "DC", viewValue: "District of Columbia" },
    { value: "FM", viewValue: "Federated States of Micronesia" },
    { value: "FL", viewValue: "Florida" },
    { value: "GA", viewValue: "Georgia" },
    { value: "GU", viewValue: "Guam" },
    { value: "HI", viewValue: "Hawaii" },
    { value: "ID", viewValue: "Idaho" },
    { value: "IL", viewValue: "Illinois" },
    { value: "IN", viewValue: "Indiana" },
    { value: "IA", viewValue: "Iowa" },
    { value: "KS", viewValue: "Kansas" },
    { value: "KY", viewValue: "Kentucky" },
    { value: "LA", viewValue: "Louisiana" },
    { value: "ME", viewValue: "Maine" },
    { value: "MH", viewValue: "Marshall Islands" },
    { value: "MD", viewValue: "Maryland" },
    { value: "MA", viewValue: "Massachusetts" },
    { value: "MI", viewValue: "Michigan" },
    { value: "MN", viewValue: "Minnesota" },
    { value: "MS", viewValue: "Mississippi" },
    { value: "MO", viewValue: "Missouri" },
    { value: "MT", viewValue: "Montana" },
    { value: "NE", viewValue: "Nebraska" },
    { value: "NV", viewValue: "Nevada" },
    { value: "NH", viewValue: "New Hampshire" },
    { value: "NJ", viewValue: "New Jersey" },
    { value: "NM", viewValue: "New Mexico" },
    { value: "NY", viewValue: "New York" },
    { value: "NC", viewValue: "North Carolina" },
    { value: "ND", viewValue: "North Dakota" },
    { value: "MP", viewValue: "Northern Mariana Islands" },
    { value: "OH", viewValue: "Ohio" },
    { value: "OK", viewValue: "Oklahoma" },
    { value: "OR", viewValue: "Oregon" },
    { value: "PW", viewValue: "Palau" },
    { value: "PA", viewValue: "Pennsylvania" },
    { value: "PR", viewValue: "Puerto Rico" },
    { value: "RI", viewValue: "Rhode Island" },
    { value: "SC", viewValue: "South Carolina" },
    { value: "SD", viewValue: "South Dakota" },
    { value: "TN", viewValue: "Tennessee" },
    { value: "TX", viewValue: "Texas" },
    { value: "UT", viewValue: "Utah" },
    { value: "VT", viewValue: "Vermont" },
    { value: "VI", viewValue: "Virgin Islands" },
    { value: "VA", viewValue: "Virginia" },
    { value: "WA", viewValue: "Washington" },
    { value: "WV", viewValue: "West Virginia" },
    { value: "WI", viewValue: "Wisconsin" },
    { value: "WY", viewValue: "Wyoming" },
    { value: "AA", viewValue: "Armed Forces Americas" },
    { value: "AE", viewValue: "Armed Forces Europe" },
    { value: "AP", viewValue: "Armed Forces Pacific" }
];

export const suffix: any[] = [
    { value: "I", viewValue: "I" },
    { value: "II", viewValue: "II" },
    { value: "III", viewValue: "III" },
    { value: "IV", viewValue: "IV" },
    { value: "V", viewValue: "V" },
    { value: "CPA", viewValue: "CPA" },
    { value: "DC", viewValue: "DC" },
    { value: "DDS", viewValue: "DDS" },
    { value: "DR", viewValue: "DR" },
    { value: "JR", viewValue: "JR" },
    { value: "SR", viewValue: "SR" },
    { value: "MD", viewValue: "MD" },
    { value: "POA", viewValue: "POA" },
    { value: "REV", viewValue: "REV" }
];

export const professionalTitle = [
    { value: "CEO", viewValue: "CEO" },
    { value: "Manager", viewValue: "Manager" },
    { value: "Managing Member", viewValue: "Managing Member" },
    { value: "Member", viewValue: "Member" },
    { value: "Officer", viewValue: "Officer" },
    { value: "Owner", viewValue: "Owner" },
    { value: "Partner", viewValue: "Partner" },
    { value: "President", viewValue: "President" },
    { value: "Secretary", viewValue: "Secretary" },
    { value: "Sole Proprietor", viewValue: "Sole Proprietor" },
    { value: "Treasurer", viewValue: "Treasurer" },
    { value: "Trustee", viewValue: "Trustee" },
    { value: "Vice President", viewValue: "Vice President" }
];