import { Platform } from "@angular/cdk/platform";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { RxReactiveFormsModule } from "@rxweb/reactive-form-validators";
import { NgxMaskDirective } from "ngx-mask";
import { AppDateAdapter, AppDateFormats } from "./app-date-adapter";
import { AppDialog } from "./components/dialog/dialog.component";
import { FileItemComponent } from "./components/file-item/file-item.component";
import { FileUploadDialog } from "./components/file-upload-dialog/file-upload-dialog.component";
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormDatePickerFieldComponent } from './components/form-date-picker-field/form-date-picker-field.component';
import { FormInputFieldComponent } from "./components/form-input-field/form-input-field.component";
import { FormInputNumberFieldComponent } from "./components/form-input-number-field/form-input-number-field.component";
import { FormRaidoGroupComponent } from './components/form-radio-group/form-radio-group.component';
import { FormSelectFieldComponent } from './components/form-select-field/form-select-field.component';
import { FormTextareaFieldComponent } from './components/form-textarea-field/form-textarea-field.component';
import { NAICSCodeComponent } from './components/naicscode/naicscode.component';
import { ValidateAddressDialog } from "./components/validate-address/validate-address.component";
import { MaskDateDirective } from "./directives/date-mask.directive";
import { DragAndDropDirectiveDirective } from "./directives/drag-and-drop-directive/drag-and-drop-directive.directive";
import { ScrollButtonDirective } from "./directives/scroll-button-directive/scroll-button.directive";
import { ExcludeSelectedValuePipe } from './pipes/exclude-selected-value.pipe';

@NgModule({
    declarations: [
        FileItemComponent,
        DragAndDropDirectiveDirective,
        FileUploadComponent,
        ScrollButtonDirective,
        AppDialog,
        ValidateAddressDialog,
        FileUploadDialog,
        FormInputFieldComponent,
        FormInputNumberFieldComponent,
        FormSelectFieldComponent,
        FormRaidoGroupComponent,
        FormTextareaFieldComponent,
        FormDatePickerFieldComponent,
        MaskDateDirective,
        NAICSCodeComponent,
        ExcludeSelectedValuePipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDividerModule,
        MatIconModule,
        MatProgressBarModule,
        MatButtonModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatDialogModule,
        MatCheckboxModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxMaskDirective,
        RxReactiveFormsModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatGridListModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: AppDateAdapter,
            deps: [MAT_DATE_LOCALE, Platform]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: AppDateFormats
        }
    ],
    exports: [
        FileItemComponent,
        FileUploadComponent,
        FileUploadDialog,
        FormInputFieldComponent,
        FormInputNumberFieldComponent,
        FormSelectFieldComponent,
        FormRaidoGroupComponent,
        FormTextareaFieldComponent,
        FormDatePickerFieldComponent,
        NAICSCodeComponent,
        ExcludeSelectedValuePipe
    ]
})
export class CoreComponentsModule { }
